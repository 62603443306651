import React, { useContext, useEffect, useRef, useState } from "react";
// import "./index.css";
import Card from "../../Component/StoreLocator/Card";
import CarouselBanner from "../../Component/StoreLocator/CarouselBanner";
import Header from "../../Component/StoreLocator/header/Header";
import SearchBar from "../../Component/StoreLocator/searchBar/SearchBar";
import AdCard from "../../Component/StoreLocator/AdCard";
// import MapComponent from "./components/MapComponent";
import "leaflet/dist/leaflet.css";
import card1 from "../../Assets/Image_lc/card-images/l2c store.jpg";
import card2 from "../../Assets/Image_lc/card-images/card2.png";
import card3 from "../../Assets/Image_lc/card-images/card3.png";
import MapBlock from "../../Component/StoreLocator/MapBlock";
import Footer from "../../Component/StoreLocator/Footer/Footer";
import "../../Css/Store.scss";
import MyContext from "../../MyContext";



const StoreLocation = () => {
  const { companyData } = useContext(MyContext);

  const storesData = [

    {
      name: "Kenya",
      address:
        "Shop 5, next to Prime bank, doctors park,3rd parklands ,nairobi kenya",
      hours: "11:00 AM to 09:30 PM",
      phone: `${companyData?.mobileNo || "N/A"}`,
      rating: "4.5",
      reviews: "522",
      image:
        "" ||
        card1,
    },
    // {
    //   name: "Jawahar Nagar",
    //   address:
    //     "Shop No 2, UA/29 Bungalow Road, Opposite Hansraj Girls Hostel, Jawahar Nagar, Delhi, 110007",
    //   hours: "11:00 AM to 10:00 PM",
    //   phone: "+91 8929811423",
    //   rating: "4.8",
    //   reviews: "1622",
    //   image:
    //     "https://www.lenskart.com/stores/_next/image?url=https%3A%2F%2Fmy-lkstore.lenskart.com%2Fstore_locator_image%2FStore_Static_Image%2FLenskartStoreFrontImageCompressed.png&w=1200&q=75" ||
    //     card2,
    //   freeGift: "Free Anti Fog Spray",
    //   isOpen: true,
    // },
    // {
    //   name: "Paschim Vihar",
    //   address: "Shop No A3/182, Ground Floor, Paschim Vihar, New Delhi, 110063",
    //   hours: "11:00 AM to 09:30 PM",
    //   phone: "+91 7428891110",
    //   rating: "4.8",
    //   reviews: "981",
    //   image:
    //     "https://www.lenskart.com/stores/_next/image?url=https%3A%2F%2Fmy-lkstore.lenskart.com%2Fstore_locator_image%2FStore_Static_Image%2FLenskartStoreFrontImageCompressed.png&w=1200&q=75" ||
    //     card3,
    //   freeGift: "",
    //   isOpen: true,
    // },
  ];

  const [isSticky, setIsSticky] = useState(false);
  const [mapPosition, setMapPosition] = useState(null);
  const searchBarRef = useRef(null);
  const [selectedmap, setselectedmap] = useState("Chhatarpur")
  const handleScroll = () => {
    if (searchBarRef.current) {
      const offsetTop = searchBarRef.current.offsetTop;
      setIsSticky(window.scrollY > offsetTop);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log(selectedmap)
  }, [selectedmap]);

  return (
    <div className="bg-[#fbf9f7] storelocater-main">
      {/* <div className="header-section !z-[20] !sticky !top-[-65px]">
        <Header />
        <SearchBar
          className={`sticky top-0  bg-white `} />
      </div> */}
      <div className="header-section custom-z custom-sticky">
        <Header />
        <SearchBar className="search-bar custom-sticky-top" />
      </div>
 
      <div className={`container2`}>
        <div className="flex-container p-4">
          <div className="left-content ">
            <CarouselBanner />
            {storesData.map((store, index) => (
              <React.Fragment key={index}>
                <Card setselectedmap={setselectedmap} store={store} />
                {index === 0 && (
                  <AdCard imageUrl="https://www.lenskart.com/stores/_next/image?url=https%3A%2F%2Fmy-lkstore.lenskart.com%2Fstore_locator_image%2FStore_Static_Image%2FLenskartStoreFrontImageCompressed.png&w=1920&q=75" />
                )}
                {index === 2 && (
                  <AdCard imageUrl="https://my-lkstore.lenskart.com/public_use/Free-lens-solution-aquagold.png" />
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="map-container2 ">
            <MapBlock selectedmap={selectedmap} userLocation={mapPosition} />
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );
};

export default StoreLocation;
